import React from "react"
import { Link } from "gatsby"
import "../../../styles/bulma-styles.scss"
import "./styles.scss"
import SEO from "../../../components/seo"

class KabudaleDBSignIn extends React.Component{
  constructor(){
    super();

    this.state = {
      username: 'akanand@iitk.ac.in',
      password: '12345679',
      signInMsg: ''
    }
  }

  componentDidMount(){
    
  }

  render(){
    const seoData = {
      title: 'MatSci AI | Materials Costimizer',
      description: 'Finding the cheapest route to manufacture high quality products your customers demand is as easy as clicking a button.',
      lang: 'en',
      metaData:{
        
      }
    }
    
    return (
      <>
        <SEO {...seoData} />
        <div className="matsci-home">
          <Link to="/">
            <img src="/full-logo-away-1.png" alt="matsci-home-logo" />
          </Link>
        </div>
        <div className="sign-in-page">
          <div className="image-overlay">
            <img className="sign-in-image" src="http://localhost:8000/signUpKabudle2.jpg" alt="sign-in-image" />
          </div>
          <div className="form">
            <div className="sign-up-section">
              Don't have an account? <Link to="/solutions/kabuddle-db/sign-up/" className="signup-button">Sign Up Here</Link>
            </div>
            <div className="kb-header">
              Sign in to KabuddleDB!
            </div>
            <div className="fill-your-details">
              Enter your details below
            </div>
            <div className="sign-in-form">
              <div class="field">
                <label class="label-1">Username</label>
                <div class="control">
                  <input class="input" type="text" placeholder="Username" value={this.state.username} onChange={e => this.setState({ username : e.target.value})} />
                </div>
              </div>

              <div class="field">
                <label class="label-1">Password</label>
                <div class="control">
                  <input class="input" type="password" placeholder="Password" value={this.state.password} onChange={e => this.setState({ password : e.target.value})} />
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <button class="button-1">Sign In</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
          <div className="privacy-policy">
            {`@${(new Date()).getFullYear()} MatSci AI, All rights Reserved.`}<Link to="/privacy-policy"> Privacy Policy</Link>
          </div>
      </>
    );
  }
}


export default KabudaleDBSignIn
